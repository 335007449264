import React, { useState } from "react";
import NavBar from "../../components/navigation-header";
import Footer from "../../components/footer";
import RigthtPage from "../../components/rigtht-page";
import { Link } from "gatsby";
import searchData from "../../data/searchdata.json";
import "../../styling/pages.css";
import { Helmet } from "react-helmet";

const App = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Number of items to display per page

  const idData = searchData.filter((newsItem) => newsItem.id);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = idData.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(idData.length / itemsPerPage);

  const handlePreviousClick = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextClick = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <div style={{ backgroundColor: "#F5F5F5" }}>
      <Helmet>
        <link
          rel="preload"
          href="https://fonts.googleapis.com/css2?family=Assistant:wght@200;300;400;500;600;700;800&display=swap"
          as="style"
        />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css2?family=Assistant:wght@200;300;400;500;600;700;800&display=swap"
        />
      </Helmet>
      <NavBar />

      <div className="pc">
        <center>
          <div className="store">
            <table
              style={{
                width: "90%",
                textAlign: "start",
                marginTop: "150px",
                marginBottom: "30px",
              }}
            >
              <tbody>
                <tr>
                  <td style={{ width: "70%", verticalAlign: "top" }}>
                    <div style={{ backgroundColor: "#fff" }}>
                      <div>
                        <div style={{ padding: "3em" }}>
                          <h1 className="categories-topic">
                            Author name: admin
                          </h1>
                        </div>
                        <div
                          style={{
                            height: "3px",
                            backgroundColor: "#F5F5F5",
                            width: "100%",
                          }}
                        ></div>
                        {currentItems.map((newsItem) => (
                          <div key={newsItem.id}>
                            <div style={{ padding: "3em" }}>
                              <Link to={`/${newsItem.readmore}/`}>
                                <img
                                  className="news-image"
                                  src={newsItem?.aimage}
                                  alt="news-image"
                                />
                              </Link>
                              <h1 className="categories-topic">
                                <Link to={`/${newsItem?.readmore}/`}>
                                  {newsItem?.topic}
                                </Link>
                              </h1>
                              <p className="categories-para">
                                <a href={newsItem?.link1}>{newsItem?.link1n}</a>{" "}
                                /{" "}
                                <a href={newsItem?.link2}>{newsItem?.link2n}</a>{" "}
                                /{" "}
                                <a href={newsItem?.link3}>{newsItem?.link3n}</a>
                              </p>
                              <p className="categories-para">
                                {newsItem?.para}
                              </p>
                              <p className="categories-para">
                                <Link to={`/${newsItem?.readmore}/`}>
                                  Read More »
                                </Link>
                              </p>
                            </div>
                            <div
                              style={{
                                height: "3px",
                                backgroundColor: "#F5F5F5",
                                width: "100%",
                              }}
                            ></div>
                          </div>
                        ))}
                      </div>
                    </div>

                    <br />
                    <div
                      style={{
                        paddingTop: "10px",
                        paddingBottom: "10px",
                        backgroundColor: "#fff",
                        width: "100%",
                      }}
                    >
                      <center>
                        {totalPages > 1 && (
                          <div>
                            <a
                              className={`link-button-search ${
                                currentPage === 1 ? "disabled" : ""
                              }`}
                              onClick={handlePreviousClick}
                            >
                              ← Previous Page
                            </a>
                            <span
                              className="page-info"
                              style={{ marginLeft: "20%", marginRight: "20%" }}
                            >
                              Page {currentPage} of {totalPages}
                            </span>
                            <a
                              className={`link-button-search ${
                                currentPage === totalPages ? "disabled" : ""
                              }`}
                              onClick={handleNextClick}
                            >
                              Next Page →
                            </a>
                          </div>
                        )}
                      </center>
                    </div>
                    <br />
                  </td>
                  <td style={{ width: "auto", verticalAlign: "top" }}>
                    <div style={{ marginLeft: "60px", marginRight: "60px" }}>
                      <RigthtPage />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </center>
      </div>

      <div className="mobile">
        <div
          style={{
            marginTop: "50px",
            marginLeft: "10px",
            marginRight: "10px",
            marginBottom: "20px",
          }}
        >
          <Helmet>
            <link
              rel="preload"
              href="https://fonts.googleapis.com/css2?family=Assistant:wght@200;300;400;500;600;700;800&display=swap"
              as="style"
            />
            <link
              rel="stylesheet"
              href="https://fonts.googleapis.com/css2?family=Assistant:wght@200;300;400;500;600;700;800&display=swap"
            />
          </Helmet>
          <div style={{ backgroundColor: "#fff" }}>
            <div>
              <div style={{ padding: "2em" }}>
                <h1 className="categories-topic">Author name: admin</h1>
              </div>
              <div
                style={{
                  height: "3px",
                  backgroundColor: "#F5F5F5",
                  width: "100%",
                }}
              ></div>
              {currentItems.map((newsItem) => (
                <div key={newsItem.id}>
                  <div style={{ padding: "2em" }}>
                    <Link to={`/${newsItem.readmore}/`}>
                      <img
                        className="news-image"
                        src={newsItem?.aimage}
                        alt="news-image"
                      />
                    </Link>
                    <h1 className="categories-topic">
                      <Link to={`/${newsItem?.readmore}/`}>
                        {newsItem?.topic}
                      </Link>
                    </h1>
                    <p className="categories-para">
                      <a href={newsItem?.link1}>{newsItem?.link1n}</a> /{" "}
                      <a href={newsItem?.link2}>{newsItem?.link2n}</a> /{" "}
                      <a href={newsItem?.link3}>{newsItem?.link3n}</a>
                    </p>
                    <p className="categories-para">{newsItem?.para}</p>
                    <p className="categories-para">
                      <Link to={`/${newsItem?.readmore}/`}>Read More »</Link>
                    </p>
                  </div>
                  <div
                    style={{
                      height: "3px",
                      backgroundColor: "#F5F5F5",
                      width: "100%",
                    }}
                  ></div>
                </div>
              ))}
            </div>
          </div>

          <br />
          <div
            style={{
              paddingTop: "10px",
              paddingBottom: "10px",
              backgroundColor: "#fff",
              width: "100%",
            }}
          >
            <center>
              {totalPages > 1 && (
                <div>
                  <a
                    className={`link-button-search ${
                      currentPage === 1 ? "disabled" : ""
                    }`}
                    onClick={handlePreviousClick}
                  >
                    ← Previous
                  </a>
                  <span
                    className="page-info"
                    style={{ marginLeft: "10%", marginRight: "10%" }}
                  >
                    Page {currentPage} of {totalPages}
                  </span>
                  <a
                    className={`link-button-search ${
                      currentPage === totalPages ? "disabled" : ""
                    }`}
                    onClick={handleNextClick}
                  >
                    Next →
                  </a>
                </div>
              )}
            </center>
          </div>
        </div>

        <div
          style={{
            marginLeft: "30px",
            marginRight: "30px",
            marginBottom: "80px",
          }}
        >
          <RigthtPage />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default App;
